<template>
  <div v-if="this.$can('processAnfragen')">
    <HSSOrderForms
      v-if="order"
      heading="Nachtrag erstellen"
      :order="order"
      is-nachtrag
      :client="client"
      :customer="customer"
      :loading="loading"
      :show-validation-error="showValidationError"
      :show-product-validation-border="showProductValidationBorder"
      hide-checkboxes
      hide-documents
      @setCustomer="setCustomer"
      @isHardknockout="isHardknockout=$event"
      @isUeberpruefung="isUeberpruefung=$event"
      @result="result = $event"
      @fillMockData="order = $event"
    >
      <template #body="{columnLayout}">
        <div
          class="col-12"
          :class="{ 'col-lg-6': columnLayout }"
        >
          <CaCard class="mb-3">
            <template #header>
              <h4 class="mb-0">
                Nachtrag Daten
              </h4>
            </template>
            <template #body>
              <BasicDatepicker
                v-model="order.nachtragGueltigAb"
                label="Gültig ab"
                :disabled-dates="disabledDatesNachtragGueltigAb"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                horizontal
                placeholder="TT.MM.JJJJ"
                :disabled="nachtragGueltigAbDisabled()"
                required
              />
              <BasicCheckbox
                v-model="order.bypassNachtragProcess"
                label="Änderung ohne Nachtrag verarbeiten"
              />
            </template>
          </CaCard>
        </div>
      </template>
      <template #buttons>
        <div
          v-if="errorMessage"
          class="w-100 text-right text-danger"
        >
          <p>{{ errorMessage }}</p>
        </div>
        <button
          type="button"
          class="btn text-primary"
          @click="abort"
        >
          Abbrechen
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="isHardknockout || pending"
          @click="save"
        >
          Nachtrag erstellen
          <i
            v-if="pending"
            class="fas fa-circle-notch fa-spin"
          />
        </button>
      </template>
    </HSSOrderForms>
  </div>
</template>

<script>
import feathers from '@/api'
import HSSOrderForms from '@/components/Forms/Order/HSSOrderForms.vue'
import CaCard from '@/components/Card.vue'
import moment from 'moment'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'
import selbstbehaltVK from '@/resources/enums/selbstbehaltVK'
import selbstbehaltTK from '@/resources/enums/selbstbehaltTK'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    HSSOrderForms,
    CaCard,
    BasicDatepicker,
    BasicCheckbox
  },
  data () {
    return {
      loading: false,
      error: null,
      order: null,
      customer: {},
      result: null,
      pending: false,
      client: {},
      isHardknockout: false,
      showValidationError: false,
      errorMessage: null
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    showProductValidationBorder () {
      if (!this.order) return false
      return !this.order.product && this.showValidationError
    },
    disabledDatesNachtragGueltigAb () {
      return {
        from: new Date(moment().endOf('day'))
      }
    },
    isHardknockoutAenderungInZukunft () {
      if (this.order.bypassNachtragProcess) return false
      return moment(moment()).isBefore(this.order.nachtragGueltigAb)
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    nachtragGueltigAbDisabled () {
      if (moment(moment()).isBefore(this.order.versicherungsbeginn)) {
        this.order.nachtragGueltigAb = this.order.versicherungsbeginn
        return true
      } else {
        return false
      }
    },
    async save () {
      this.showValidationError = !await this.$validator.validateAll() || !this.order.product
      if (this.showValidationError) return
      this.pending = true
      this.errorMessage = null
      try {
        await feathers.service('hs-order-process-nachtrag-erstellen').create(this.order)
        this.$router.push({ name: 'HSSOrderDetail', params: { orderId: this.order._id } })
      } catch (e) {
        this.errorMessage = e
        console.error(e)
      } finally {
        this.pending = false
      }
    },
    async abort () {
      this.$router.push({ name: 'HSSOrderDetail', params: { orderId: this.order._id } })
    },
    async fetchData () {
      try {
        this.loading = true
        this.order = await this.$store.dispatch('hs-order/get', this.$route.params.orderId)
        const clientReq = await this.$store.dispatch('clients/find', {
          query: {
            $limit: 1
          }
        })
        this.client = clientReq.data[0]
        this.customer = this.order.customer
        const komfortPackages = this.order.productPackages.KOMFORT
        const exclusivPackages = this.order.productPackages.EXCLUSIV
        const kompatkPackages = this.order.productPackages.KOMPAKT

        komfortPackages.weltweiterVersicherungsschutzKasko = komfortPackages.weltweiterVersicherungsschutzKasko || 'no'
        komfortPackages.zusatzpaketFreiesFahren = komfortPackages.zusatzpaketFreiesFahren || 'no'
        komfortPackages.selbstbehalt = komfortPackages.selbstbehalt || selbstbehaltVK.TK150VK500.api

        exclusivPackages.weltweiterVersicherungsschutzKasko = exclusivPackages.weltweiterVersicherungsschutzKasko || 'no'
        exclusivPackages.zusatzpaketFreiesFahren = exclusivPackages.zusatzpaketFreiesFahren || 'no'
        exclusivPackages.selbstbehalt = exclusivPackages.selbstbehalt || selbstbehaltVK.TK150VK500.api

        kompatkPackages.selbstbehalt = kompatkPackages.selbstbehalt || selbstbehaltTK.TK150.api
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    setCustomer (newCustomer) {
      this.customer = newCustomer
    }
  }
}
</script>

<style scoped lang="scss">

.order-info {
  position: sticky;
  top: 70px;
}

</style>
